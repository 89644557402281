<template>
  <Layout>
    <div class="app-help">
      <center>
        <h2>客服微信</h2>
        <p>
          <img src="@/assets/kf.png" />
        </p>
      </center>
      <h2>如何开户</h2>
      <div>
        <p>开户费用：1000元，联系客服付款，并提供以下信息：</p>
        <ol>
          <li>本系统用户名</li>
          <li>
            视频号直播间绑定的微信号
          </li>
          <li>
            需要打开微信号加好友，设置路径：我 - 设置 - 隐私 - 添加我的方式 -
            微信号
          </li>
        </ol>
      </div>
      <h2>发布商品成功，但是直播挂不了商品</h2>
      <p>请联系客服付款开户，才能挂商品。</p>
      <h2>公众号关联小程序步骤</h2>
      <p>
        发布公众号链接时，需要提前关联公众号到工具小程序。<a
          href="https://developers.weixin.qq.com/miniprogram/introduction/#%E5%85%AC%E4%BC%97%E5%8F%B7%E5%85%B3%E8%81%94%E5%B0%8F%E7%A8%8B%E5%BA%8F"
          target="_blank"
        >
          点击查看教程 </a
        >，<br />
        工具小程序AppID： wxcdbe8088c121dbee
      </p>

      <h2>如何发布视频号直播预约？</h2>
      <p>
        实现直播预约功能，需要关联公众号到小程序，然后发布预约公众号文章，<a
          href="https://developers.weixin.qq.com/community/develop/article/doc/00066237680fd8319c2c5499651813"
          target="_blank"
        >
          点击查看教程
        </a>
      </p>

      <h2>为什么发布商品会失败？</h2>
      <p>所有发布商品都会经过微信审核，审核通过才能上架直播间。</p>
      <p>
        审核失败，请检查发布标题、主图是否有明显的违规行为。请做相应的调整后再发布。
      </p>

      <h2>商品上架中，可以更换二维码、链接吗？</h2>
      <p>
        可以，商品没有下架前，都可以编辑商品，只允许更改二维码和链接，不支持编辑标题、主图和类型等内容。
      </p>

      <h2>一个账号可以挂多少个视频号？</h2>
      <p>一个。</p>

      <h2>图片加载慢</h2>
      <p>请缩小图片尺寸，二维码图片建议不超过300x300，文件大小在300k以内</p>
      <p>开始活动前打开页面测试，会提前预热图片，提高加载速度。</p>

      <h2>是否可以频繁下架商品？</h2>
      <p>如果商品没有自动下架，建议保留在线。</p>
      <p>
        因为每个商品上架都需要审核，1、审核需要时间；2、即使相同内容审核也不一定通过。
      </p>

      <h2>提示已经存在商品？</h2>
      <p>请调整标题或主图后，重试。</p>

      <h2>什么样的标题容易被拒绝？</h2>
      <p>包含免费等文字的标题，主图也一样。</p>

      <h2>审核需要多久？</h2>
      <p>商品审核有微信完成，一般几分钟到10几分钟不等。</p>

      <h2>视频号收到安全提示信息</h2>
      <p>请按照视频号直播规范进行直播。</p>

      <!-- <h2>请问不用了，可以退费吗？</h2>
      <p>已开发票的、对公转账，不能退款；</p>
      <p>其他情况可以退剩下的余额，款项由微信支付原路退回。</p>
      <p>退款后，将进行视频号解除绑定，一个视频号最多只允许绑定2次。</p> -->

      <h2>系统计费规则</h2>
      <p>
        工具按具体使用量进行扣费（先预存后消费点数）；
      </p>
      <p>
        具体消费策略按照商品数量、上架时长、点击次数3个维度计算：
      </p>
      <ol>
        <li>
          每个已上架商品每天消费10元（商品数量不限，当天上架时长不足24小时也按1天计费）
        </li>
        <li>商品下架后，从次日零点开始停止计费</li>
        <li>
          每个二维码类型商品每次点击计费0.1点
        </li>
        <li>
          每个小程序类型商品每次点击计费0.2点
        </li>
        <li>展示公众号文章商品不限制点击次数</li>
      </ol>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

export default {
  data() {
    return {
      title: process.env.VUE_APP_TITLE
    };
  },

  methods: {},

  components: {
    Layout
  }
};
</script>

<style lang="scss">
.app-help {
  font-size: 18px;

  h2 {
    margin-top: 50px;

    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
