/* global _ */

import axios from "axios";
// import _ from "lodash";

import { getToken } from "./auth";

export const BASE_URL = process.env.VUE_APP_BASE_URL;
export const UPLOAD_URL = process.env.VUE_APP_UPLOAD_URL;

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
});

instance.defaults.timeout = 30000;

instance.interceptors.request.use(
  config => {
    let token = getToken();
    if (token) {
      config.headers["t"] = token;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    const res = response.data;
    switch (res.code) {
      case "0":
      case "200":
        return res.data;
      // todo 错误处理
      // case "401":
      //   // reject(401);
      //   window.location.pathname = "/#/logout";
      //   break;
      case "400":
      default:
        throw new Error(res.msg);
    }
  },
  error => {
    const errorMsg = _.get(error, "response.data.error.message");
    if (errorMsg) {
      throw new Error(errorMsg);
    }
    throw new Error(error);
  }
);

export default instance;
