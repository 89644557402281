<template>
  <div class="app-index">
    <div><a-spin /></div>
    <a-button v-if="isNetworkError" @click="reload" style="margin-top:20px">
      刷新
    </a-button>
  </div>
</template>

<script>
const UserSvc = require("@/service/user");

export default {
  data() {
    return {
      isNetworkError: false
    };
  },

  methods: {
    reload() {
      this.loadData();
    },

    async loadData() {
      try {
        const info = await UserSvc.info();
        if (!info) {
          this.$message.error("系统在开小差，请稍等");
          return;
        }

        if (!info.username) {
          // 初始化
          this.$router.replace({ name: "UserInit" });
        } else if (!info.is_pay) {
          // 付款
          this.$router.replace({ name: "UserPay" });
        } else if (!info.is_active) {
          // 联系客服
          this.$router.replace({ name: "UserKf" });
        } else if (info.balance <= 50) {
          // 充值
          this.$router.replace({ name: "Charge" });
        } else {
          this.$router.replace({ name: "Act" });
        }
      } catch (err) {
        // 错误处理
        if (err.message == "Error: Network Error") {
          this.isNetworkError = true;

          this.$message.error("网络故障，请稍等重试");
        } else {
          this.$router.replace({ name: "Logout" });
        }
      }
    }
  },

  async mounted() {
    this.loadData();
  }
};
</script>

<style>
.app-index {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
