<template>
  <div class="app-user-init">
    <a-page-header title="开户流程" />
    <a-steps style="margin:0 10px 20px;">
      <a-step status="finish" title="微信登录"> </a-step>
      <a-step status="process" title="设置密码"> </a-step>
      <a-step status="wait" title="确认开户"> </a-step>
      <a-step status="wait" title="人工审核"> </a-step>
    </a-steps>
    <div>
      <a-form-model class="form-link" :model="form" ref="form">
        <a-form-model-item
          required
          label="账号"
          prop="username"
          :rules="[
            {
              required: true,
              message: '账号必填'
            },
            {
              min: 2,
              message: '账号至少2个字符'
            },
            {
              max: 20,
              message: '账号最多20个字符'
            }
          ]"
        >
          <a-input v-model="form.username" />
        </a-form-model-item>

        <a-form-model-item
          required
          label="密码"
          prop="password"
          :rules="[
            {
              required: true,
              message: '密码必填'
            },
            {
              min: 6,
              message: '密码至少6个字符'
            },
            {
              max: 32,
              message: '密码最多32个字符'
            }
          ]"
        >
          <a-input-password v-model="form.password" />
        </a-form-model-item>

        <a-button type="primary" @click="submit" block size="large"
          >提交</a-button
        >
      </a-form-model>
    </div>
  </div>
</template>

<script>
const UserSvc = require("@/service/user");

export default {
  name: "UserInit",

  data() {
    return {
      form: {
        username: "",
        password: ""
      }
    };
  },

  methods: {
    async submit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            await UserSvc.init(this.form);
            this.$message.info("设置成功");

            // 跳转首页
            this.$router.replace({ name: "UserPay" });
          } catch (err) {
            console.error(err);
            this.$message.error("设置失败，错误：" + err.message);
          }
        } else {
          console.log("error submit!!");

          return false;
        }
      });
    }
  },

  components: {}
};
</script>

<style lang="scss">
.app-user-init {
  width: 100%;
  height: 100vh;
  padding: 20px;

  .qrcode-box {
    width: 340px;
    padding: 16px 20px;
    border-radius: 6px;
    border: 1px solid #ccc;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .ant-spin-nested-loading {
      width: 164px;
      height: 164px;
      margin-bottom: 10px;
    }
  }
}
</style>
