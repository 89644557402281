import { default as request, BASE_URL } from "./request";

/**
 * 账号密码登录
 */
export const login = data => request.post("/l", data);

/**
 * 微信登录URL
 */
export const loginByWeixinUrl = BASE_URL + "/l2";

/**
 * 微信登录
 */
export const loginByWeixin = code =>
  request.get("/login", { params: { code } });
