import request from "./request";

/**
 * 订单
 */

/**
 * 创建
 */
export const create = (code, wx) =>
  request.get("/pay/new", { params: { code, wx } });

/**
 * 订单状态
 */
export const orderStatus = id => request.get("/pay/status", { params: { id } });
