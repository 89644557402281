export function loggedIn() {
  return !!localStorage.getItem("token");
}

export function userLogin(token) {
  localStorage.setItem("token", token);
}

export function getToken() {
  return localStorage.getItem("token");
}

export function logout() {
  localStorage.removeItem("token");
}
