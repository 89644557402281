<template>
  <div class="app-login">
    <a-page-header title="登录" />
    <a-form-model class="form-login" :model="form" ref="form">
      <a-form-model-item
        required
        label="账号"
        prop="username"
        :rules="[
          {
            required: true,
            message: '账号必填'
          },
          {
            min: 2,
            message: '账号至少2个字符'
          },
          {
            max: 20,
            message: '账号最多20个字符'
          }
        ]"
      >
        <a-input v-model="form.username" />
      </a-form-model-item>

      <a-form-model-item
        required
        label="密码"
        prop="password"
        :rules="[
          {
            required: true,
            message: '密码必填'
          },
          {
            min: 6,
            message: '密码至少6个字符'
          },
          {
            max: 32,
            message: '密码最多32个字符'
          }
        ]"
      >
        <a-input-password v-model="form.password" />
      </a-form-model-item>

      <a-form-model-item>
        <a-button type="primary" @click="submit" block size="large">
          登录
        </a-button>
      </a-form-model-item>

      <div>
        <a-button
          @click="weixin"
          block
          ghost
          size="large"
          type="primary"
          icon="wechat"
          >微信登录</a-button
        >
      </div>
    </a-form-model>
  </div>
</template>

<script>
import { userLogin } from "@/service/auth";
const LoginSvc = require("@/service/login");

export default {
  data() {
    return {
      loginUrl: LoginSvc.loginByWeixinUrl,
      form: { username: "", password: "" }
    };
  },

  methods: {
    weixin() {
      window.location.href = LoginSvc.loginByWeixinUrl;
    },

    async submit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            const { token } = await LoginSvc.login(this.form);
            userLogin(token);

            this.$message.info("登录成功");

            // 跳转首页
            this.$router.replace({ name: "Index" });
          } catch (err) {
            console.error(err);
            this.$message.error("登录失败，错误：" + err.message);
          }
        } else {
          console.log("error submit!!");

          return false;
        }
      });
    }
  },

  components: {
    //
  }
};
</script>

<style lang="scss">
.app-login {
  width: 100%;
  height: 100vh;
  padding: 20px;
}
</style>
