<template>
  <div class="app-user-pay">
    <a-page-header title="开户流程" />
    <a-steps style="margin:0 10px 20px;">
      <a-step status="finish" title="微信登录"> </a-step>
      <a-step status="finish" title="设置密码"> </a-step>
      <a-step status="process" title="确认开户"> </a-step>
      <a-step status="wait" title="人工审核"> </a-step>
    </a-steps>
    <div class="desc">
      <p style="font-size:18px;text-weight:bold;color:#00b;">
        软件价格：1000元（联系客服付款，系统自动赠送100点）
      </p>
      <p>
        1、开户费：1000元，包含技术服务、视频号直播间绑定（每个用户限绑定一个视频号）、公众号绑定（数量不限）
      </p>
      <p>
        2、工具按具体使用量进行扣费（先预存后消费点数）；
      </p>
      <p>
        具体消费策略按照商品数量、上架时长、点击次数3个维度计算：
      </p>
      <ol>
        <li>
          每个已上架商品每天消费10元（商品数量不限，当天上架时长不足24小时也按1天计费）
        </li>
        <li>商品下架后，从次日零点开始停止计费</li>
        <li>
          每个二维码类型商品每次点击计费0.1点
        </li>
        <li>
          每个小程序类型商品每次点击计费0.2点
        </li>
        <li>展示公众号文章商品不限制点击次数</li>
      </ol>
      <p>
        注册后添加客服微信，付款完成开户
      </p>
    </div>

    <div class="pay-area">
      <div v-if="qrcode" style="text-align:center;">
        <vue-qrcode :width="200" :value="qrcode" />
        <div>微信扫码支付</div>
      </div>
      <div v-else>
        <p style="color:red">
          严禁使用本系统发布违反国家法律法规的内容；<br />
          严禁发布违反微信视频号、视频号直播规则的内容；<br />
          您将承担发布内容的所有责任，如发生相关法律纠纷，与本公司无关。<br />
          <a-checkbox v-model="agree">
            我已了解清楚并同意以上声明内容。
          </a-checkbox>
        </p>
        <a-button
          :disabled="!agree"
          type="primary"
          @click="pay"
          size="large"
          block
          style="height:44px"
        >
          确认开户
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueQrcode from "vue-qrcode";

const OrderSvc = require("@/service/order");

export default {
  name: "UserPay",

  data() {
    return {
      agree: false,
      qrcode: "",
      orderId: ""
    };
  },
  computed: {
    ...mapState(["isWeixin"])
  },

  async mounted() {},

  methods: {
    async pay() {
      const isWeixin = this.isWeixin;
      const res = await OrderSvc.create("open", isWeixin);
      const { code_url, id, payed } = res;

      // 免费
      if (payed) {
        this.$router.replace({ name: "UserKf" });
        return;
      }

      if (isWeixin) {
        window.WeixinJSBridge.invoke("getBrandWCPayRequest", res, res => {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            this.$message.info("充值成功");

            this.$router.replace({ name: "UserKf" });
          }
        });
      } else {
        this.qrcode = code_url;
        this.orderId = id;

        this.timer = setTimeout(this.checkOrderStatus, 5000);
      }
    },

    async checkOrderStatus() {
      try {
        const { status } = await OrderSvc.orderStatus(this.orderId);
        if (status === 1) {
          this.$router.replace({ name: "Index" });
        } else {
          this.timer = setTimeout(this.checkOrderStatus, 5000);
        }
      } catch (err) {
        console.error(err);

        this.timer = setTimeout(this.checkOrderStatus, 5000);
      }
    }
  },

  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },

  components: {
    VueQrcode
  }
};
</script>

<style lang="scss">
.app-user-pay {
  width: 100%;
  height: 100vh;
  padding: 20px;

  .desc {
    font-size: 18px;
    padding-bottom: 300px;
  }

  .pay-area {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background: #fff;
    padding: 10px 10px 30px 10px;
    border-top: 1px solid #eee;
    box-shadow: #e0e0e0 0px -5px 10px 4px;
  }
}
</style>
