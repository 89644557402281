var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-login"},[_c('a-page-header',{attrs:{"title":"登录"}}),_c('a-form-model',{ref:"form",staticClass:"form-login",attrs:{"model":_vm.form}},[_c('a-form-model-item',{attrs:{"required":"","label":"账号","prop":"username","rules":[
        {
          required: true,
          message: '账号必填'
        },
        {
          min: 2,
          message: '账号至少2个字符'
        },
        {
          max: 20,
          message: '账号最多20个字符'
        }
      ]}},[_c('a-input',{model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1),_c('a-form-model-item',{attrs:{"required":"","label":"密码","prop":"password","rules":[
        {
          required: true,
          message: '密码必填'
        },
        {
          min: 6,
          message: '密码至少6个字符'
        },
        {
          max: 32,
          message: '密码最多32个字符'
        }
      ]}},[_c('a-input-password',{model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})],1),_c('a-form-model-item',[_c('a-button',{attrs:{"type":"primary","block":"","size":"large"},on:{"click":_vm.submit}},[_vm._v(" 登录 ")])],1),_c('div',[_c('a-button',{attrs:{"block":"","ghost":"","size":"large","type":"primary","icon":"wechat"},on:{"click":_vm.weixin}},[_vm._v("微信登录")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }