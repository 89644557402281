import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    /** 用户信息 */
    user: {},
    /** 微信内 */
    isWeixin: false
  },
  getters: {},
  mutations: {
    setUserInfo(state, data) {
      state.user = data || {};
    },

    setIsWeixin(state, isWeixin) {
      state.isWeixin = isWeixin || {};
    }
  },
  actions: {},
  modules: {}
});
