<template>
  <div class="app-login">
    <a-page-header title="开户流程" />
    <a-steps style="margin:0 10px 20px;">
      <a-step status="process" title="微信登录"> </a-step>
      <a-step status="wait" title="设置密码"> </a-step>
      <a-step status="wait" title="确认开户"> </a-step>
      <a-step status="wait" title="人工审核"> </a-step>
    </a-steps>
    <div>
      <a-button
        @click="weixin"
        block
        ghost
        size="large"
        type="primary"
        icon="wechat"
        >微信登录</a-button
      >
    </div>
  </div>
</template>

<script>
import { userLogin } from "@/service/auth";
const LoginSvc = require("@/service/login");

export default {
  data() {
    return {
      loginUrl: LoginSvc.loginByWeixinUrl,
      form: { username: "", password: "" }
    };
  },

  methods: {
    weixin() {
      window.location.href = LoginSvc.loginByWeixinUrl;
    },

    async submit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            const { token } = await LoginSvc.login(this.form);
            userLogin(token);

            this.$message.info("登录成功");

            // 跳转首页
            this.$router.replace({ name: "Index" });
          } catch (err) {
            console.error(err);
            this.$message.error("登录失败，错误：" + err.message);
          }
        } else {
          console.log("error submit!!");

          return false;
        }
      });
    }
  },

  components: {
    //
  }
};
</script>

<style lang="scss">
.app-login {
  width: 100%;
  height: 100vh;
  padding: 20px;
}
</style>
