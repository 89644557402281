<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import { mapMutations } from "vuex";

import zhCN from "ant-design-vue/es/locale/zh_CN";

export default {
  data() {
    return {
      locale: zhCN
    };
  },

  computed: {},
  methods: {
    ...mapMutations(["setIsWeixin"]),

    onWeixinReady() {
      this.setIsWeixin(true);
    }
  },

  mounted() {
    if (typeof window.WeixinJSBridge == "undefined") {
      if (document.addEventListener) {
        document.addEventListener(
          "WeixinJSBridgeReady",
          this.onWeixinReady,
          false
        );
      } else if (document.attachEvent) {
        document.attachEvent("WeixinJSBridgeReady", this.onWeixinReady);
        document.attachEvent("onWeixinJSBridgeReady", this.onWeixinReady);
      }
    } else {
      this.onWeixinReady();
    }
  }
};
</script>

<style lang="scss">
.fluid-row {
  .ant-col {
    float: right;

    &.ant-form-item-label {
      float: left;
    }
  }
}
</style>
