<template>
  <div class="app-user-kf">
    <a-page-header title="开户流程" />
    <a-steps style="margin:0 10px 20px;">
      <a-step status="finish" title="微信登录"> </a-step>
      <a-step status="finish" title="设置密码"> </a-step>
      <a-step status="finish" title="确认开户"> </a-step>
      <a-step status="process" title="人工审核"> </a-step>
    </a-steps>
    <div class="desc">
      <p>请扫码添加客服微信付款，审核开通</p>
      <p>提供以下信息：</p>
      <ol>
        <li>本系统注册用户账号名称</li>
        <li>
          视频号直播间绑定的微信号
        </li>
        <li>
          需要打开微信号加好友，设置路径：我 - 设置 - 隐私 - 添加我的方式 -
          微信号
        </li>
      </ol>
      <center>
        <h2>客服微信</h2>
        <img src="@/assets/kf.png" />
      </center>
    </div>
    <div>
      <a-button
        type="primary"
        @click="payed"
        size="large"
        block
        style="height:44px"
      >
        我已付款
      </a-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserKf",

  data() {
    return {};
  },

  methods: {
    payed() {
      this.$router.replace({ name: "Index" });
    }
  },

  components: {}
};
</script>

<style lang="scss">
.app-user-kf {
  width: 100%;
  height: 100vh;
  padding: 20px;

  .desc {
    font-size: 18px;
  }
}
</style>
