<template>
  <div class="app-login-wx"><a-spin /></div>
</template>

<script>
import { userLogin } from "@/service/auth";
const LoginSvc = require("@/service/login");

export default {
  name: "LoginWX",

  async mounted() {
    try {
      // 登录
      const { code } = this.$route.query || {};

      if (!code) {
        this.$router.replace({ name: "Login" });
        return;
      }

      const { token } = await LoginSvc.loginByWeixin(code);
      userLogin(token);

      // 跳转首页
      this.$router.replace({ name: "Index" });
    } catch (err) {
      console.error(err);
      this.$message.error("登录失败，错误：" + err.message);

      this.$router.replace({ name: "Logout" });
    }
  },

  methods: {}
};
</script>

<style>
.app-login-wx {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
