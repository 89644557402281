// import Vue from "vue";
import VueRouter from "vue-router";

import Index from "./pages/Index.vue";
import Login from "./pages/Login.vue";
import LoginWX from "./pages/LoginWX.vue";
import Logout from "./pages/Logout.vue";
import Register from "./pages/Register.vue";

import UserInit from "./pages/UserInit.vue";
import UserPay from "./pages/UserPay.vue";
import UserKf from "./pages/UserKf.vue";

import Help from "./pages/Help.vue";

const auth = require("@/service/auth");

const routes = [
  {
    path: "/register",
    name: "Register",
    component: Register
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/wx",
    name: "LoginWX",
    component: LoginWX
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout
  },

  {
    path: "/user/init",
    name: "UserInit",
    component: UserInit
  },
  {
    path: "/user/pay",
    name: "UserPay",
    component: UserPay
  },
  {
    path: "/user/kf",
    name: "UserKf",
    component: UserKf
  },

  {
    path: "/help",
    name: "Help",
    meta: { requiresAuth: true },
    component: Help
  },

  {
    path: "/act",
    name: "Act",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "act" */ "./pages/Activity.vue")
  },
  {
    path: "/charge",
    name: "Charge",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "charge" */ "./pages/Charge.vue")
  },
  {
    path: "/user/password",
    name: "ChangePassword",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "password" */ "./pages/ChangePassword.vue")
  },

  {
    path: "/",
    name: "Index",
    component: Index
  },

  {
    path: "*",
    name: "404",
    component: Index
  }
];

const router = new VueRouter({
  // mode: "history",
  routes,
  linkActiveClass: "active"
});

// 登录限制
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!auth.loggedIn()) {
      next({
        path: "/login"
      });
    } else {
      next();
    }
  } else {
    next(); // 确保一定要调用 next()
  }
});

export default router;
