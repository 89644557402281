<template>
  <div class="app-logout"></div>
</template>

<script>
import { logout } from "@/service/auth";
const UserSvc = require("@/service/user");

export default {
  async mounted() {
    try {
      await UserSvc.logout();
    } catch (err) {
      //
    }

    logout();

    // this.$message.info("已退出登录");
    this.$router.replace({ name: "Login" });
  },

  methods: {}
};
</script>

<style>
.app-logout {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
